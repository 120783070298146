import { Footer, FooterAlt, FooterLite, Header } from '@aspendental/shared-components-web';
import { ReactNode } from 'react';

import { AdFooter, App, Maybe, NavigationLink, Page } from '@src/lib/__generated/sdk';

interface LayoutPropsInterface {
	children: ReactNode;
	appData: Maybe<App>;
	footerData: Maybe<AdFooter>;
	isFooterLite?: boolean;
	isFooterAlt?: boolean;
	withSimpleLayout?: boolean;
	locale: string;
	preview: boolean;
	showHeaderNavMenu: boolean;
	breadcrumbsData: Page;
	locationNavigationLinks?: Array<NavigationLink | Page> | undefined;
	withFixedHeader: boolean;
}

function getFooter(
	footerData: Maybe<AdFooter>,
	isFooterLite: boolean,
	isFooterAlt: boolean,
	withSimpleLayout: boolean,
	breadcrumbsData: Page | undefined
) {
	if (isFooterLite) {
		return (
			<FooterLite disclaimer={footerData?.disclaimer} footerData={footerData} breadcrumbsData={breadcrumbsData} />
		);
	} else if (isFooterAlt) {
		return <FooterAlt footerData={footerData} />;
	}
	return (
		<Footer
			footerData={footerData}
			withScheduling={withSimpleLayout}
			breadcrumbsData={breadcrumbsData}
			isLightFooter={!!footerData?.isLightFooter}
		/>
	);
}

export const Layout = ({
	appData,
	footerData,
	isFooterLite = true,
	isFooterAlt = false,
	withSimpleLayout = false,
	children,
	showHeaderNavMenu,
	breadcrumbsData,
	locationNavigationLinks,
	withFixedHeader,
}: LayoutPropsInterface) => {
	return (
		<>
			{appData && (
				<Header
					locationNavigationLinks={locationNavigationLinks}
					appData={appData}
					withOfficeDetails
					showHeaderNavMenu={showHeaderNavMenu}
					phoneNumber={appData?.header?.phoneNumber}
					withFixedHeader={withFixedHeader}
					useHeaderLocationCards={appData?.header?.useHeaderLocationCards}
				/>
			)}
			{children}
			{footerData && getFooter(footerData, isFooterLite, isFooterAlt, withSimpleLayout, breadcrumbsData)}
		</>
	);
};
