import { TAGThemeProvider } from '@aspendental/shared-components-web';
import { AppContextProvider } from '@aspendental/shared-components-web/lib/context/AppContextProvider.ctx';
import { FacilityContextProvider } from '@aspendental/shared-components-web/lib/context/FacilityContextProvider.ctx';
import { TAGBrand } from '@aspendental/shared-components-web/lib/types';
import { ContentfulLivePreviewProvider } from '@contentful/live-preview/react';
import { StyledEngineProvider } from '@mui/material';
import { useRouter } from 'next/router';
import { CookiesProvider, useCookies } from 'react-cookie';
import { Client, Provider as QueryProvider, fetchExchange } from 'urql';

import { contentfulGraphQLURL } from 'codegen';
const queryClient = new Client({
	url: contentfulGraphQLURL,
	exchanges: [fetchExchange],
	requestPolicy: 'network-only',
	fetchOptions: () => {
		return {
			headers: {
				Authorization: `Bearer ${process.env.CONTENTFUL_ACCESS_TOKEN}`,
				'content-type': 'application/json',
			},
		};
	},
});

const previewQueryClient = new Client({
	url: contentfulGraphQLURL,
	exchanges: [fetchExchange],
	requestPolicy: 'network-only',
	fetchOptions: () => {
		return {
			headers: {
				Authorization: `Bearer ${process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN}`,
				'content-type': 'application/json',
			},
		};
	},
});

export const ContextProviders = ({
	config,
	preview,
	environment,
	user,
	children,
	onPageScheduling,
	pageTheme,
	defaultToCdn = false,
	dentrinoClientId,
	dentrinoClientSecret,
	deviceType,
}) => {
	const router = useRouter();
	const [cookies] = useCookies(['tag-theme']);
	const brandTheme = cookies['tag-theme'] || config?.themeId || process.env.TAG_BRAND_THEME || 'aspendental';
	const brandName = brandTheme as TAGBrand;
	const gqlClient = preview ? previewQueryClient : queryClient;
	return (
		<QueryProvider value={gqlClient}>
			<ContentfulLivePreviewProvider
				locale={router.locale || 'en-US'}
				enableInspectorMode={preview}
				enableLiveUpdates={preview}
			>
				<CookiesProvider>
					<AppContextProvider
						config={config}
						theme={brandName}
						environment={environment}
						appName={process.env.APP_NAME || 'tag_dev'}
						appVersion={`${process.env.APP_VERSION || 'unknown'}(design-system:${
							process.env.DESIGN_SYSTEM_VERSION
						})`}
						dentrinoClientId={dentrinoClientId}
						dentrinoClientSecret={dentrinoClientSecret}
					>
						<StyledEngineProvider injectFirst>
							<TAGThemeProvider
								branding={brandName}
								pageTheme={pageTheme}
								deviceType={deviceType || 'mobile'}
							>
								<FacilityContextProvider
									brand={brandName}
									user={user}
									onPageScheduling={onPageScheduling}
									defaultToCdn={defaultToCdn}
								>
									{children}
								</FacilityContextProvider>
							</TAGThemeProvider>
						</StyledEngineProvider>
					</AppContextProvider>
				</CookiesProvider>
			</ContentfulLivePreviewProvider>
		</QueryProvider>
	);
};
