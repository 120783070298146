import { createTheme, GlobalStyles, GlobalStylesProps, ThemeProvider, ScopedCssBaseline } from '@mui/material';
import { overrideTheme } from '@aspendental/themes';
import { match } from 'css-mediaquery';

import { PageTheme } from '@/context/PageThemeProvider.ctx';
import { resolveBrandThemeConfig } from '@/utils';

import { TAGBrand } from '../types';

import * as features from './tagfeatures';
import { FontPreloader } from './FontPreloader';

declare module '@mui/material/styles' {
	interface Theme {
		brand: string;
		tagFeatures: {
			PAGE_THEME_ENABLED: boolean;
		};
	}
	interface ThemeOptions {
		brand: string;
		tagFeatures?: {
			PAGE_THEME_ENABLED?: boolean;
		};
	}
}

const globalStyles: GlobalStylesProps['styles'] = {
	html: { scrollBehavior: 'smooth' },
	'html, body': {
		width: '100%',
		margin: 0,
	},
	body: {
		overflowX: 'hidden',
	},
	'@media screen and (prefers-reduced-motion: reduce)': {
		html: {
			scrollBehavior: 'auto',
		},
	},
};
export interface TAGThemeProviderProps {
	branding: TAGBrand & string;
	pageTheme?: PageTheme;
	children?: React.ReactNode;
	deviceType?: 'desktop' | 'mobile';
}

export default function TAGThemeProvider({
	branding = TAGBrand.AspenDental,
	pageTheme,
	children,
	deviceType = 'mobile',
}: TAGThemeProviderProps) {
	const isMottoTheme = pageTheme === 'Motto';
	const brandFeatures = features[branding];
	const brandTheme = resolveBrandThemeConfig(isMottoTheme ? TAGBrand.Motto : branding);

	const theme = createTheme(
		overrideTheme(brandTheme, {
			brand: branding,
			tagFeatures: {
				...brandFeatures,
			},
			components: {
				// https://mui.com/material-ui/react-use-media-query/#server-side-rendering
				MuiUseMediaQuery: {
					defaultProps: {
						ssrMatchMedia: (query) => ({
							matches: match(query, {
								width: deviceType === 'mobile' ? '0px' : '901px',
							}),
						}),
					},
				},
			},

			breakpoints: {
				...brandTheme.breakpoints,
			},
		})
	);

	return (
		<ThemeProvider theme={theme}>
			<FontPreloader brand={branding} />
			<ScopedCssBaseline id="mui-scoped-baseline">
				<GlobalStyles styles={globalStyles} />
				{children}
			</ScopedCssBaseline>
		</ThemeProvider>
	);
}
