import { memo } from 'react';

import Head from 'next/head';

import { TAGBrand } from '@/types';

import MessinaSansWebBookWoff2 from '../assets/fonts/MessinaSans/MessinaWeb/MessinaSans/MessinaSansWeb-Book.woff2';
import MessinaSansWebRegularWoff2 from '../assets/fonts/MessinaSans/MessinaWeb/MessinaSans/MessinaSansWeb-Regular.woff2';
import MessinaSansWebSemiBoldWoff2 from '../assets/fonts/MessinaSans/MessinaWeb/MessinaSans/MessinaSansWeb-SemiBold.woff2';
import MessinaSansWebBoldWoff2 from '../assets/fonts/MessinaSans/MessinaWeb/MessinaSans/MessinaSansWeb-Bold.woff2';
import MessinaSansMonoWebRegularWoff2 from '../assets/fonts/MessinaSans/MessinaWeb/MessinaSansMono/MessinaSansMonoWeb-Regular.woff2';
import TiemposHeadlineWebRegularWoff2 from '../assets/fonts/TiemposHeadlineWeb/tiempos-headline-web-regular.woff2';
import MessinaModernWebRegularWoff2 from '../assets/fonts/MessinaModern/StandardVersion/MessinaModernWeb-Regular.woff2';
import AvenirBookWoff2 from '../assets/fonts/Avenir/Avenir-Book.woff2';
import AvenirHeavyWoff2 from '../assets/fonts/Avenir/Avenir-Heavy.woff2';
import AvenirMediumWoff2 from '../assets/fonts/Avenir/Avenir-Medium.woff2';
import BeausiteClassicWebRegularWoff2 from '../assets/fonts/BeausiteClassicWeb/BeausiteClassicWeb-Regular.woff2';
import BeausiteClassicWebMediumWoff2 from '../assets/fonts/BeausiteClassicWeb/BeausiteClassicWeb-Medium.woff2';
import BeausiteClassicWebBoldWoff2 from '../assets/fonts/BeausiteClassicWeb/BeausiteClassicWeb-Bold.woff2';
import BurgessRegularProWoff2 from '../assets/fonts/Burgess/burgess-regular-pro.woff2';
import BrandonGrotesqueRegularOtf from '../assets/fonts/BrandonGrotesque/BrandonGrotesque-Regular.otf';
import QanelasSoftBoldWoff2 from '../assets/fonts/QanelasSoft/QanelasSoft-Bold.woff2';
import QanelasSoftRegularWoff2 from '../assets/fonts/QanelasSoft/QanelasSoft-Regular.woff2';
import ObjektivMk1TrialRgWoff from '../assets/fonts/ObjektivMk1Trial/ObjektivMk1_Trial_Rg.woff';
import ObjektivMk1TrialMdWoff from '../assets/fonts/ObjektivMk1Trial/ObjektivMk1_Trial_Md.woff';
import ObjektivMk1TrialSBdWoff from '../assets/fonts/ObjektivMk1Trial/ObjektivMk1_Trial_SBd.woff';
import ObjektivMk1TrialBdWoff from '../assets/fonts/ObjektivMk1Trial/ObjektivMk1_Trial_Bd.woff';
import EBGaramondRegularWoff2 from '../assets/fonts/EBGaramond/EBGaramondWeb/EBGaramond-Regular.woff2';
import EBGaramondSemiBoldWoff2 from '../assets/fonts/EBGaramond/EBGaramondWeb/EBGaramond-SemiBold.woff2';
import EBGaramondBoldWoff2 from '../assets/fonts/EBGaramond/EBGaramondWeb/EBGaramond-Bold.woff2';
import DIN2014NarrowW05DemiWoff2 from '../assets/fonts/DIN2014/DIN2014NarrowW05-Demi.woff2';
import DIN2014NarrowW05ExtraLightWoff2 from '../assets/fonts/DIN2014/DIN2014NarrowW05-ExtraLight.woff2';
import DIN2014NarrowW03DemiWoff2 from '../assets/fonts/DIN2014/DIN2014NarrowW03-Demi.woff2';
import DIN2014NarrowW03ExtraLightWoff2 from '../assets/fonts/DIN2014/DIN2014NarrowW03-ExtraLight.woff2';
import DIN2014NarrowW10DemiWoff2 from '../assets/fonts/DIN2014/DIN2014NarrowW10-Demi.woff2';
import DIN2014NarrowW10ExtraLightWoff2 from '../assets/fonts/DIN2014/DIN2014NarrowW10-ExtraLight.woff2';
import ABCArizonaFlareWoff2 from '../assets/fonts/ABCArizonaFlare/ABCArizonaFlare-Regular.woff2';
import HelveticaNeueLTProWoff2 from '../assets/fonts/HelveticaNeueLTPro/HelveticaNeueLTPro-Roman.woff2';
import HelveticaNeueLTProBoldWoff2 from '../assets/fonts/HelveticaNeueLTPro/HelveticaNeueLTPro-Bold.woff2';
import HelveticaNeueLTProLtWoff2 from '../assets/fonts/HelveticaNeueLTPro/HelveticaNeueLTPro-Lt.woff2';

export const fontList: Record<string, { href: unknown; type?: string }[]> = {
	'Messina Sans': [
		{ href: MessinaSansWebBookWoff2, type: 'font/woff2' },
		{ href: MessinaSansWebRegularWoff2, type: 'font/woff2' },
		{
			href: MessinaSansWebSemiBoldWoff2,
			type: 'font/woff2',
		},
		{ href: MessinaSansWebBoldWoff2, type: 'font/woff2' },
	],
	'Messina Sans Mono': [
		{
			href: MessinaSansMonoWebRegularWoff2,
			type: 'font/woff2',
		},
	],
	'Tiempos Headline': [{ href: TiemposHeadlineWebRegularWoff2, type: 'font/woff2' }],
	'Messina Modern': [{ href: MessinaModernWebRegularWoff2, type: 'font/woff2' }],
	Avenir: [
		{ href: AvenirBookWoff2, type: 'font/woff2' },
		{ href: AvenirHeavyWoff2, type: 'font/woff2' },
		{ href: AvenirMediumWoff2, type: 'font/woff2' },
	],
	'Beausite Classic': [
		{ href: BeausiteClassicWebRegularWoff2, type: 'font/woff2' },
		{ href: BeausiteClassicWebMediumWoff2, type: 'font/woff2' },
		{ href: BeausiteClassicWebBoldWoff2, type: 'font/woff2' },
	],
	'Burgess Pro': [{ href: BurgessRegularProWoff2, type: 'font/woff2' }],

	'Brandon Grotesque': [{ href: BrandonGrotesqueRegularOtf, type: 'font/opentype' }],
	'Qanelas Soft': [
		{ href: QanelasSoftBoldWoff2, type: 'font/woff2' },

		{ href: QanelasSoftRegularWoff2, type: 'font/woff2' },
	],
	'Objektiv Mk1 Trial': [
		{ href: ObjektivMk1TrialRgWoff, type: 'font/woff' },
		{ href: ObjektivMk1TrialMdWoff, type: 'font/woff' },
		{ href: ObjektivMk1TrialSBdWoff, type: 'font/woff' },
		{ href: ObjektivMk1TrialBdWoff, type: 'font/woff' },
	],
	'EB Garamond': [
		{ href: EBGaramondRegularWoff2, type: 'font/woff2' },
		{ href: EBGaramondSemiBoldWoff2, type: 'font/woff2' },
		{ href: EBGaramondBoldWoff2, type: 'font/woff2' },
	],
	'DIN 2014 Narrow W05 Demi': [
		{ href: DIN2014NarrowW05DemiWoff2, type: 'font/woff2' },
		{ href: DIN2014NarrowW05ExtraLightWoff2, type: 'font/woff2' },
		{ href: DIN2014NarrowW03DemiWoff2, type: 'font/woff2' },
		{ href: DIN2014NarrowW03ExtraLightWoff2, type: 'font/woff2' },
		{ href: DIN2014NarrowW10DemiWoff2, type: 'font/woff2' },
		{ href: DIN2014NarrowW10ExtraLightWoff2, type: 'font/woff2' },
	],
	'ABC Arizona Flare': [{ href: ABCArizonaFlareWoff2, type: 'font/woff2' }],
	'Helvetica Neue LT Pro': [
		{ href: HelveticaNeueLTProWoff2, type: 'font/woff2' },
		{ href: HelveticaNeueLTProBoldWoff2, type: 'font/woff2' },
		{ href: HelveticaNeueLTProLtWoff2, type: 'font/woff2' },
	],
};

export function getFonts(brand: TAGBrand) {
	let fonts = [];
	switch (brand) {
		case TAGBrand.AspenDental:
			fonts = ['Messina Sans', 'Tiempos Headline'];
			break;
		case TAGBrand.Chapter:
			fonts = ['ABC Arizona Flare', 'Helvetica Neue LT Pro'];
			break;
		case TAGBrand.ClearChoice:
			fonts = ['Beausite Classic', 'Burgess Pro'];
			break;
		case TAGBrand.AZPetVet:
		case TAGBrand.Lovet:
			fonts = ['Gill Sans', 'Brandon Grotesque'];
			break;
		case TAGBrand.Motto:
			fonts = ['EB Garamond', 'Qanelas Soft'];
			break;
		case TAGBrand.WellNow:
			fonts = ['Avenir'];
			break;
		case TAGBrand.LivWellNow:
			fonts = ['Avenir', 'Montserrat'];
			break;
		case TAGBrand.TeamTAG:
			fonts = ['Messina Modern', 'DIN 2014 Narrow W05 Demi'];
			break;
		default:
			fonts = ['Messina Sans', 'Tiempos Headline'];
			break;
	}
	return fonts.flatMap((fontName) => fontList[fontName] ?? []);
}

export const FontPreloader = memo(function FontPreloader({ brand }: Readonly<{ brand: TAGBrand }>) {
	const fontData = getFonts(brand);
	return (
		<Head>
			{fontData.map((data, i) => {
				const href = data.href as string;
				return (
					<link
						key={`${i}_${href}`}
						rel="preload"
						href={href}
						as="font"
						type={data.type}
						crossOrigin=""
					></link>
				);
			})}
		</Head>
	);
});
